<template>
  <div v-if="checkPermission('list', 'action', 'credit-use-reports')">
    <topic-component :topic="$t('SideBarItems.reports.list.creditReport')"/>
    <b-row class="mb-1">
      <b-col class="box-topic-credit" v-for="item in boxList" :key="item.text">
        <b-card class="h-100">
          <div align="center">
            <h1 class="text-primary">{{ changeNumberFormat(item.total) }}</h1>
            <h5>{{ $t(item.text) }}</h5>
          </div>
        </b-card>
      </b-col>
      <b-col class="box-topic-credit">
        <b-card no-body class="pr-2 pl-2 h-100">
          <div align="center">
            <label style="font-size: 18px"> API</label>
            <b-row>
              <b-col align="left">
                <label> {{ $t('report.credit.total') }}</label>
              </b-col>
              <b-col align="right">
                <h5 class="text-bold text-primary">{{ api_total }}</h5>
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col align="left">
                <label> {{ $t('report.credit.quickSendAPI') }}</label>
              </b-col>
              <b-col align="right">
                <h5 class="text-bold text-primary">{{ api_quick_send ? api_quick_send.toLocaleString() : 0 }}</h5>
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col align="left">
                <label>{{ $t('report.credit.campaignAPI') }}</label>
              </b-col>
              <b-col lg="3" sm="6" md="6" align="right">
                <h5 class="text-bold text-primary">{{ api_campaign ? api_campaign.toLocaleString() : 0 }}</h5>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col lg="4" md="6" sm="12">
          <b-form-group label-cols="12"
                        :label="$t('common.dateTime')" label-for="input-sm">
            <date-time-range-component v-model="time" type="date"></date-time-range-component>
          </b-form-group>
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient" @click="findCreditUsed">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="findCreditUsed"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-table
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="$t('empty')"
          show-empty
      >
        <template #head()="{ label, field: { key, sortable }}">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </template>
        <template #empty="scope">
          <empty-table/>
        </template>
        <template #cell(createdAt)="data">
          {{ changeDateFormat(data.item.createdAt) }} {{ data.item.is_free_trial === 1 ? '( Free trial )' : '' }}
        </template>
        <template #cell(quick_send)="data">
          {{ changeNumberFormat(data.item.express_amount) }}
        </template>
        <template #cell(file)="data">
          {{ changeNumberFormat(data.item.file_amount) }}
        </template>
        <template #cell(campaign)="data">
          {{ changeNumberFormat(data.item.campaign_amount) }}
        </template>
        <template #cell(api)="data">
          {{ changeNumberFormat(data.item.api_amount) }}
        </template>
        <template #cell(otp)="data">
          {{ changeNumberFormat(data.item.otp_amount) }}
        </template>
        <template #cell(total_credit_used_amount)="data">
          <span class="text-bold text-blue">{{ changeNumberFormat(data.item.total_amount) }}</span>
        </template>
      </b-table>
      <b-row>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findCreditUsed"
          />
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import TopicComponent from "@/components/common/topicComponent";
import reportApi from "@/repository/reportApi";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import moment from "moment";
import masterData from "@/common/masterData.json";
import functionsCommon from "@/common/functions";
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "CreditUsed",
  components: {TotalListComponent, EmptyTable, DateTimeRangeComponent, TopicComponent},
  data: () => ({
    totalAll: 0,
    time: [
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
    ],
    boxList: [
      {total: 0, text: "report.credit.total"},
      {total: 0, text: "report.credit.quickSend"},
      {total: 0, text: "report.credit.file"},
      {total: 0, text: "report.credit.campaign"},
      {total: 0, text: "report.credit.otp"},
    ],
    typeList: masterData.creditTopUpList,
    packageList: [],
    form: {
      by: '',
      date_from: null,
      date_to: null,
      $skip: 1,
      $limit: 10,
    },
    api_total: 0,
    api_campaign: 0,
    api_quick_send: 0,
    isBusy: false,
    fields: [
      {
        key: "createdAt",
        label: "report.creditTopUp.list.date",
      },
      {
        key: "quick_send",
        label: "report.credit.quickSend",
      },
      {
        key: "file",
        label: "report.credit.file",
      },
      {
        key: "campaign",
        label: "report.credit.campaign",
      },
      {
        key: "api",
        label: "report.credit.api",
      },
      {
        key: "otp",
        label: "report.credit.otp",
      },
      {
        key: "total_credit_used_amount",
        label: "report.credit.total",
      },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
  }),
  mounted() {
    this.findCreditUsed()
  },
  computed: {},
  methods: {
    changeNumberFormat(data) {
      return data ? functionsCommon.changeNumberFormat(data) : '0'
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateNotTimeFormat(date) : '-'
    },
    resetSearch() {
      const data = {
        by: '',
        date_from: null,
        date_to: null,
        $skip: 1,
        $limit: 10,
      }
      this.form = data
      this.time = [
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
      ]
      this.findCreditUsed()
    },
    findCreditUsed() {
      this.findCreditUsedSummary()
      this.isBusy = !this.isBusy
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      reportApi.getCreditUsedAll(_.cloneDeep(this.form)).then(response => {
        this.totalAll = response.total
        this.totalRows = response.total
        this.items = response.data
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    findCreditUsedSummary() {
      const data = {
        date_from: moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z',
        date_to: moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      }
      reportApi.getCreditUsedSumary(data).then((response) => {
        this.api_total = response?.total_api_amount
        this.api_quick_send = response?.total_sms_api_amount
        this.api_campaign = response?.total_sms_campaign_api_amount
        this.boxList[0].total = response?.total_amount;
        this.boxList[1].total = response?.total_express_amount;
        this.boxList[2].total = response?.total_file_amount;
        this.boxList[3].total = response?.total_campaign_amount;
        this.boxList[4].total = response?.total_otp_amount;
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}

.box-topic-credit {
  background-color: var(--primary-color);
  border-radius: 15px;
  margin: 10px;
  border: 1px solid var(--primary-color)
}
</style>
